<template>
    <div
        style="cursor: pointer; z-index: 3;"
        class=" mt-10 "
        @click="dialog = true"
    >
        <v-btn
            style="float: right; z-index: 3;"
            class="ml-1"
            fab
            dark
            x-small
            color="white"
            outlined
        >
            <v-icon dark>
                mdi-information-variant
            </v-icon>
        </v-btn>
        <span
            class="text-center white--text mt-3"
            style="font-size: 0.7em"
        >
            {{ $t('global.help.instructionHowToDownloadAccess') }}
        </span>

        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                persistent
                max-width="80%"
                scrollable
            >
                <v-card class="sessions__instructions__card">
                    <v-card-title class="text-h5">
                        {{$t('sessions.instructions.title')}}
                    </v-card-title>
                    <v-card-text>
                        <section class="mt-8">
                            <h6 v-text="$t('sessions.instructions.section1.title')"></h6>
                            <div v-html="$t('sessions.instructions.section1.text')"></div>
                        </section>
                        <section class="mt-10">
                            <h6 v-text="$t('sessions.instructions.section2.title')"></h6>
                            <div v-html="$t('sessions.instructions.section2.text')"></div>
                        </section>
                        <section class="mt-10">
                            <h6 v-text="$t('sessions.instructions.section3.title')"></h6>
                            <div v-html="$t('sessions.instructions.section3.text')"></div>
                        </section>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                        >
                            Ok
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "instructions",
    data() {
        return {
            dialog: false
        }
    }
}
</script>

<style >
.sessions__instructions__card p {
    margin-bottom: 3px;
}
</style>
